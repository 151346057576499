<template>
    <div class="rating-item" v-if="rating">
        <div class="font-weight-bold">{{ rating.title }}</div>
        <RatingStars :rating="rating.rating" />
        <div>{{ rating.description }}</div>
        <div class="small mt-2" v-if="byUserId">
            To- {{ rating.for_user && rating.for_user.name }}
        </div>
        <div class="small mt-2" v-else>By- {{ rating.by_user && rating.by_user.name }}</div>
        <RatingButton
            v-if="!hideRatingButton"
            :forUserId="forUserId"
            @updateRating="emitUpdate()"
        />
    </div>
</template>

<script>
import RatingStars from "./RatingStars.vue"
import RatingButton from "./RatingButton.vue"

export default {
    emits: ["update"],
    props: {
        hideRatingButton: {
            type: Boolean,
            default: false,
        },
        rating: {
            type: Object,
            default: () => null,
        },
        forUserId: {
            type: Number,
        },
        byUserId: {
            type: Number,
        },
    },
    components: { RatingStars, RatingButton },
    methods: {
        emitUpdate() {
            console.log("rating  item update")
            this.$emit("update")
        },
    },
}
</script>

<style lang="scss" scoped>
.rating-item {
    padding: 10px 25px;
    background: #eee;
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.5);
    margin: 10px 0;
    max-width: 500px;
}
</style>
