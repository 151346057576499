<template>
    <!-- THE RATING LOGIC HAS BEEN EXTENDED TO OTHER USERS LIKE BUYER AND SELLER ALSO BUT THE TABLE NAME / CONTROLLER NAME / FUNCTION NAME  MAY BE MISLEADING WHICH IS MATERIAL TESTER RATING -->

    <div class="rating-modal" v-if="showModal">
        <div class="rating-modal-header">
            <div>{{ editMode ? "Edit your rating" : "Add your rating" }}</div>
            <span class="close rating-modal-close" @click="hideModal()">&times;</span>
        </div>
        <div class="rating-modal-body">
            <form @submit.prevent="submitRating()">
                <input type="text" class="form-control my-2" v-model="title" placeholder="title" />
                <textarea v-model="description" class="form-control" placeholder="description" />
                <RatingStars editMode :rating="rating" @update="rating = $event" />
                <button type="submit" class="primary-button ">
                    {{ editMode ? "Save Changes" : "Submit" }}
                </button>
            </form>
        </div>
    </div>
</template>

<script>
import RatingStars from "./RatingStars.vue"
import request from "../../apis/request"

export default {
    emits: ["ratingChange"],
    props: {
        showModal: {
            type: Boolean,
            default: false,
        },
        forUserId: {
            type: Number,
        },
        editMode: {
            type: Boolean,
            default: false,
        },
        oldRating: {
            type: Object,
            default: () => null,
        },
    },
    data() {
        return {
            title: "",
            description: "",
            rating: 0,
        }
    },
    components: {
        RatingStars,
    },
    async mounted() {
        if (this.editMode) {
            const { id, title, description, rating } = this.oldRating
            ;(this.title = title), (this.description = description)
            this.rating = rating
        }
    },
    methods: {
        async submitRating() {
            if (!this.title || !this.description || !this.rating > 0) {
                return this.$toasted("Please fill up all the fields properly and rate the user.")
            }
            if (this.editMode) {
                // save the rating
                try {
                    const res = await request.post("/material-testers/rating/update", {
                        id: this.oldRating.id,
                        title: this.title,
                        description: this.description,
                        rating: this.rating,
                    })
                    this.$toasted.success(res.data.message)
                    this.$emit("ratingChange")
                    this.hideModal()
                } catch (err) {
                    console.log("error in updating rating", err)
                    this.$toasted.error(
                        err.response && err.response.data && err.response.data.message
                    )
                }
            } else {
                // create a new rating
                try {
                    const res = await request.post("/material-testers/rating", {
                        for_user_id: this.forUserId,
                        title: this.title,
                        description: this.description,
                        rating: this.rating,
                    })
                    this.$toasted.success(res.data.message)
                    this.$emit("ratingChange")
                    this.hideModal()
                } catch (err) {
                    console.log("error in posting rating", err)
                    this.$toasted.error(
                        err.response && err.response.data && err.response.data.message
                    )
                }
            }
        },
        hideModal() {
            this.$emit("hideModal")
        },
    },
}
</script>

<style lang="scss" scoped>
.rating-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
    background: white;
    max-width: 80%;
    width: 500px;
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.5);
    z-index: 100;
    .rating-modal-header {
        padding: 10px;
        display: flex;
        justify-content: space-between;

        .rating-modal-close {
            color: white;
            background: red;
            padding: 5px;
            height: 30px;
            width: 30px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .rating-modal-body {
        padding: 10px;
    }
}
</style>
