<template>
    <!-- THE RATING LOGIC HAS BEEN EXTENDED TO OTHER USERS LIKE BUYER AND SELLER ALSO BUT THE TABLE NAME / CONTROLLER NAME / FUNCTION NAME  MAY BE MISLEADING WHICH IS MATERIAL TESTER RATING -->

    <div v-if="showRatingButton">
        <button @click="showModal = true" class="primary-button d-inline">
            {{ btnTitle }}
        </button>
        <RatingModal
            :forUserId="forUserId"
            :showModal="showModal"
            @hideModal="hideModal()"
            :editMode="editMode"
            :oldRating="oldRating"
            @ratingChange="$emit('ratingChange')"
        />
    </div>
</template>

<script>
import request from "../../apis/request"
import RatingModal from "./RatingModal.vue"

export default {
    emits: ["update", "ratingChange"],
    props: {
        title: {
            type: String,
            default: "Rate the Material Inspector",
        },
        forUserId: {
            type: Number,
        },
        allowForOtherUsers: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            showModal: false,
            showRatingButton: false,
            editMode: false,
            oldRating: null,
            btnTitle: this.title,
        }
    },
    components: {
        RatingModal,
    },
    async mounted() {
        await this.checkRatingAllowed()
        // this.btnTitle = this.title
    },
    methods: {
        async checkRatingAllowed() {
            try {
                let url = "/material-testers/check-rating-allowed?for_user_id=" + this.forUserId
                if (this.allowForOtherUsers) {
                    url += "&allowForOtherUsers=true"
                }
                const res = await request.get(url)
                const data = res.data
                console.log("rating allowed", res)
                this.editMode = false

                if (data.allowRating) {
                    this.showRatingButton = true
                } else if (data.allowEditRating) {
                    this.editMode = true
                    this.showRatingButton = true
                    this.oldRating = data.result
                    this.btnTitle = "Edit My Rating"
                } else {
                    this.showRatingButton = false
                }
            } catch (err) {
                console.log("error in checking rating allowed", err)
                this.showRatingButton = false
            }
        },
        async hideModal() {
            this.showModal = false
            console.log("emitting update rating")
            this.$emit("updateRating")
            await this.checkRatingAllowed()
        },
    },
}
</script>

<style></style>
