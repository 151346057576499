<template>
    <!-- THE RATING LOGIC HAS BEEN EXTENDED TO OTHER USERS LIKE BUYER AND SELLER ALSO BUT THE TABLE NAME / CONTROLLER NAME / FUNCTION NAME  MAY BE MISLEADING WHICH IS MATERIAL TESTER RATING -->

    <div>
        <div class="ratings-title">{{ title }}</div>
        <hr />
        <div v-if="!ratings.length && !isLoading">
            Not rated by any user.
        </div>
        <div v-else-if="!hideAverageRating">
            <div>Average rating</div>

            <div class="small my-2">{{ materialTester.average_rating || averageRating }} Stars</div>
            <div class="small my-2">
                <RatingStars :rating="materialTester.average_rating || averageRating" />
            </div>
        </div>
        <div class="row">
            <div class="col-md-6" v-for="rating in ratings" :key="rating.id">
                <RatingItem
                    :hideRatingButton="hideRatingButton"
                    :rating="rating"
                    :key="rating.id"
                    :forUserId="forUserId"
                    :byUserId="byUserId"
                    @update="updateRatings"
                />
            </div>
        </div>
    </div>
</template>

<script>
import RatingItem from "./RatingItem.vue"
import RatingStars from "./RatingStars.vue"
import request from "../../apis/request"

export default {
    props: {
        title: {
            type: String,
            default: "Ratings",
        },
        forUserId: {
            type: Number,
        },
        byUserId: {
            type: Number,
        },
        materialTester: {
            type: Object,
        },
        hideRatingButton: {
            type: Boolean,
            default: false,
        },
        notIsMaterialTester: {
            type: Boolean,
            default: false,
        },
        isMaterialTester: {
            type: Boolean,
            default: false,
        },
        hideAverageRating: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        RatingItem,
        RatingStars,
    },
    data() {
        return {
            ratings: [],
            isLoading: false,
            averageRating: 0,
        }
    },
    async mounted() {
        this.isLoading = true
        await this.fetchRatings()
        this.isLoading = false
    },
    methods: {
        async fetchRatings() {
            let res
            const suffix = `&is_material_tester=${this.isMaterialTester}&not_is_material_tester=${this.notIsMaterialTester}`
            if (this.forUserId) {
                res = await request.get(
                    "/material-testers/ratings?for_user_id=" + this.forUserId + suffix
                )
            }
            if (this.byUserId) {
                res = await request.get(
                    "/material-testers/ratings?by_user_id=" + this.byUserId + suffix
                )
            }
            const data = res.data
            this.ratings = data.result
            this.averageRating = data.average_rating
        },
        async updateRatings() {
            console.log("update ratings running")
            await this.fetchRatings()
        },
    },
}
</script>

<style lang="scss" scoped>
.ratings-title {
    font-size: 1.3em;
    margin-top: 25px;
}
</style>
